// FONTS
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap");
$font1: "Space Grotesk", sans-serif;

// COLORS
$landing-background: #160f31;
$white: #ffffff;
$black: #000000;
$orange-gradient: linear-gradient(90deg, #fc6964, #fcc442);
$purple-gradient: linear-gradient(
  90deg,
  rgba(158, 0, 255, 0) 4.65%,
  rgba(114, 8, 179, 0.49) 27.47%,
  #451165 51.22%,
  rgba(114, 8, 179, 0.49) 74.96%,
  rgba(158, 0, 255, 0) 97.78%
);
$red-danger: #ff0000;
$green-success: #00ff00;
// MARGIN PADDING
/*
This .scss file will provide a full list of helper classes for margins and paddings for your HTML.
My suggestion is to use in rems instead of pixels, but you can easily change the values in px.
You can customize the $prefix variable to avoid issues with frameworks or decide to remove it at all, it's up to you.
The "m" and "p" letters inside classes are for margin and padding respectively. Same logic is used for $sides keys ("t" for top, "l" for left and so on..).
The generated classes follow the examples below:
for MARGIN:
  .s-m-2 { margin: .25rem; }  // .25rem for all margins
  .s-mt-5 { margin-top: 1rem; }  // 1rem for margin top
for PADDING:
  .s-p-0 { padding: 0rem; }  // 0rem for all paddings
  .s-pl-7 { padding-left: 2rem; }  // 2rem for padding left
REMINDER: use GULP (or GRUNT) to build your .css file.
*/

// All measures are expressed in rems instead of pixels

$prefix: s;

// $space-values: (0, .125, .25, .5, .75, 1, 1.5, 2, 2.5, 3);

$space-values: (
  "0": "0",
  "1": "0.125",
  "2": "0.25",
  "3": "0.5",
  "4": "0.75",
  "5": "1",
  "6": "1.5",
  "7": "2",
  "8": "2.5",
  "9": "3",
);

$sides: (
  "": "all",
  "t": "top",
  "r": "right",
  "b": "bottom",
  "l": "left",
);

@each $space-key, $space-value in $space-values {
  @each $side-key, $side-value in $sides {
    $property: if($side-key == "", "", -#{$side-value});

    .#{$prefix}-m#{$side-key}-#{$space-key} {
      margin#{$property}: #{$space-value}rem;
    }

    .#{$prefix}-p#{$side-key}-#{$space-key} {
      padding#{$property}: #{$space-value}rem;
    }
  }
}

//Space function
@function space($n) {
  @return nth(0 0.125 0.25 0.5 0.75 1 1.5 2 2.5 3, $n + 1) * 1rem;
}

//MEDIA QUERIES
$mediaXL: 1200px;
$mediaLG: 992px;
$mediaMD: 768px;
$mediaXS: 576px;

//ELEMENT STYLES
h1 {
  margin: space(0);
  font-size: 4rem;
  font-weight: 700;
  line-height: 100%;
  color: $white;
}

h2 {
  margin: space(0);
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 100%;
  color: $white;
}

h3 {
  margin: space(0);
  font-size: 2.188rem;
  line-height: 120%;
  color: $white;
}

h4 {
  margin: space(0);
  font-size: 1.875rem;
  line-height: 120%;
  color: $white;
}

p {
  margin: space(0);
  font-size: 1.563rem;
  line-height: 120%;
  color: $white;
}

button {
  cursor: pointer;
}
button:hover {
  opacity: 0.8;
}
button:active {
  opacity: 0.6;
}
button:disabled {
  opacity: 0.2;
  cursor: default;
}

@media (max-width: $mediaLG) {
  h1 {
    font-size: 3.75rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.5rem;
  }
}

@media (max-width: $mediaXS) {
  h1 {
    font-size: 3.25rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.625rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
  }
}
