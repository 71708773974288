@import "./styles.scss";

.main {
  width: 100%;
  max-width: 1440px;
}

.main-section,
.bored-section,
.first-form-section,
.wineries-section,
.revolution-section,
.second-form-section,
.winenfts-section,
.best-sellers-section {
  width: 100%;
  padding-top: space(6);
  padding-bottom: space(6);
  gap: space(6);
}

.title__p {
  margin: space(0);
  padding: space(0);
  font-family: Montserrat, sans-serif;
  text-align: center;
  font-size: 1rem;
  line-height: 120%;
  font-weight: 400;
}

.title-heading {
  margin: space(0);
  padding: space(0);
  > * {
    width: 100%;
    max-width: 540px;
  }
}

.winenft-heading {
  margin: space(0);
  padding: space(0);
  > * {
    width: 100%;
    max-width: 900px;
  }
}

.title-heading__image {
  width: 100%;
  max-width: 480px;
  height: auto;
  margin: space(0) space(5);
}

.title-heading__button {
  width: 120px;
  margin: space(0);
  padding: space(4) space(7);
  font-family: Montserrat, sans-serif;
  line-height: 120%;
  font-size: 1rem;
  color: $white;
  background: $orange-gradient;
  border: 0px;
  border-radius: 22px;
}

.button__spinner {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.175rem;
  background: transparent;
}

.landing-form__message {
  width: 100%;
  height: 0.875rem;
}

.landing-form__message-p {
  font-size: 0.875rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

.landing-form__message-p--success {
  color: $white;
}

.landing-form__message-p--error {
  color: $white;
}

.wineries-section,
.revolution-section,
.second-form-section {
  > * {
    width: 100%;
  }
  * {
    background-color: inherit;
  }
}

.wineries-section__img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.revolution-section__h4 {
  max-width: 600px;
}

.card-winenft {
  width: 100%;
  max-width: 300px;
  padding: space(5);
  background: linear-gradient($landing-background, $landing-background)
      padding-box,
    $orange-gradient border-box;
  border-radius: 10px;
  border: 2px solid transparent;
}

.card-winenft__text {
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: $white;
}

.card-best-seller {
  width: 100%;
  max-width: 300px;
}

.card-best-seller__container {
  width: 100%;
}

.card-best-seller__p {
  font-size: 0.875rem;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: $white;
}

.card-best-seller__second-img {
  width: 100%;
  max-width: 52px;
  height: auto;
}

.landing-form {
  width: 100%;
  max-width: 464px;
  > * {
    width: 100%;
  }
}

.landing-form__input {
  width: 100%;
  max-width: 464px;
  padding: space(3) space(5);
  background-color: $white;
  border: 0px;
  border-radius: 22px;
}

.landing-form__input option {
  background-color: $white;
  color: $black;
}

.landing-form__input option:disabled {
  background-color: #f0f0f0;
  color: #999;
}

.error-message {
  padding: space(0) space(5);
  height: 1rem;
  font-size: 0.675rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  color: $red-danger;
}

.whatsapp-icon {
  position: fixed;
  bottom: space(5);
  right: space(7);
  z-index: 1;
  background: transparent;
  * {
    background-color: inherit;
  }
}

@media (max-width: $mediaLG) {
  .header {
    padding: space(6) space(4);
  }
}

@media (max-width: $mediaXS) {
  .title-heading__button {
    padding: space(4) space(8);
    line-height: 120%;
    font-size: 1.125rem;
    border: 0px;
    border-radius: 268px;
  }

  .button__spinner {
    width: 1.35rem;
    height: 1.35rem;
  }
}

.wineries-section {
  width: 100%;
}
