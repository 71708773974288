@import "./styles/styles.scss";

.text-gradient {
  background: $orange-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.purple-gradient {
  background: $purple-gradient !important;
}

.truncate-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
}
