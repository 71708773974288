@import "./styles.scss";

.header {
  width: 100%;
  margin: space(0);
  padding: space(6);
  gap: space(2);
  &__p-language {
    margin: space(0);
    padding: space(0);
    font-family: Montserrat, sans-serif;
    text-align: center;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    &--hovered {
      cursor: pointer;
    }
    &--hovered:hover {
      opacity: 0.8;
    }
    &--hovered:active {
      opacity: 0.6;
    }
  }
}

@media (max-width: $mediaLG) {
  .header {
    padding: space(6) space(4);
  }
}

@media (max-width: $mediaXS) {
  .header {
    padding: space(6) space(3);
  }
}
