@import "./styles.scss";

.footer {
  margin: space(0);
  padding: space(7);
  width: 100%;
  height: 100%;
  min-height: 70px;
  &__p {
    max-width: 200px;
  }
  &-social__svg:hover {
    opacity: 0.8;
  }
  &-social__svg:active {
    opacity: 0.6;
  }
}
